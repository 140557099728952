<template>
  <div id="menu" class="menu-box">
    <div class="logo">
      <img src="@/assets/logo.png" alt />
    </div>
    <div class="m-list-box" v-for="(item, index) in list" :key="index">
      <div
        :key="index"
        @click="handleAction(item.route)"
        class="item"
        :class="item.route == defaultActive ? 'actionClass' : ''"
      >
        <img
          class="icon"
          :src="require('@/assets/img/menu/icon' + (index + 1) + '.png')"
          alt
        />
        <span class="title">{{ item.name }}</span>
      </div>
    </div>
    <div class="exit-login" @click="handleExit()">
      <img class="img" src="@/assets/img/menu/icon6.png" alt />
      <span class="text">退出 Exit</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginName: localStorage.getItem('loginName'),
      userRoleType: localStorage.getItem('userRoleType'),
      phoneNumber: '',
      name: '',
      defaultActive: '/',
      list: [
        {
          name: '总览 Overview',
          icon: 'icon-shouye',
          route: '/',
        },
        {
          name: '设备 Machine',
          icon: 'icon-sucaiku',
          route: '/devicePage',
        },
        {
          name: '指令 Instruct',
          icon: 'icon-zhandian',
          route: '/controlPage',
        },
        {
          name: '部件 Component',
          icon: 'icon-caidan',
          route: '/partsPage',
        },
      ],
    };
  },

  mounted() {
    this.defaultActive = this.$route.path;
  },
  watch: {
    $route() {
      this.defaultActive = this.$route.path;
    },
  },
  computed: {
    // getActive(){
    //   // 导航蓝根据当前页面关联高亮
    //   // if (this.$route.path.indexOf("/programManage") == 0) {
    //   //   return "/programManage";
    //   // } else {
    //     // console.log(this.$route.path)
    //     console.log(this.$route.path,"----")
    //     return this.$route.path;
    //   // }
    // }
  },
  methods: {
    handleAction(r) {
      if (r == this.defaultActive) return false;
      this.defaultActive = r;
      this.$router.push(r);
    },
    handleExit() {
      // 退出
      localStorage.setItem('Access-Token', '');
      this.$router.push({
        path: '/login',
      });
      window.close();
    },
  },
};
</script>
<style lang="scss" scoped>
#menu {
  height: 100%;
  width: 235px;
  background-image: url('../../../assets/img/menu/menu_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  .logo {
    width: 146px;
    height: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    margin: 42px auto 26px;
    overflow: hidden;
    img {
      width: 146px;
      height: 114px;
      display: block;
    }
  }
  .m-list-box {
    width: 100%;
    overflow: hidden;
  }
  .item {
    width: 199px;
    height: 52px;
    display: flex;
    //justify-content: center;
    align-items: center;
    height: 54px;
    font-size: 20px;
    color: #fff;
    margin: 34px auto 0;
    color: #fff;
    cursor: pointer;
    .icon {
      display: block;
      width: 26px;
      height: 26px;
      margin-right: 18px;
    }
  }
  .actionClass {
    font-size: 20px;
    border-radius: 10px;
    background: rgba(235, 92, 31, 0.06);
    box-sizing: border-box;
    border: 1px solid #eb5c1f;
  }

  .exit-login {
    width: 126px;
    height: 48px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 53px;
    bottom: 58px;
    cursor: pointer;
    .img {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
    .text {
      font-size: 20px;
      color: #ffffff;
    }
  }
}
</style>
