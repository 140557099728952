<template>
  <div class="wrap" ref="appRef">
    <div class="content-box">
      <div class="box-left">
        <a-aside></a-aside>
      </div>
      <div class="box-right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from '../utils/drawMixin';
import leftMenu from './components/menu/menu.vue';
export default {
  mixins: [drawMixin],
  components: {
    'a-aside': leftMenu,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 1920px;
  height: 1080px;
  // background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;
  .content-box {
    display: flex;
    height: 100%;
    overflow: hidden;
  }
  .box-left {
    flex-shrink: 0;
    width: 246px;
    height: 100%;
    background-color: #eb5c1f;
  }
  .box-right {
    flex: 1;
    height: 100%;
  }
}
</style>
